import * as React from "react";
import { Layout } from "../components/layout";
import { News } from "../components/news";
import { graphql } from "gatsby";

const NewsPage = (props) => {
  const data = props.data.allNewsYaml.edges;
  return (
    <>
      <Layout title="Aktuelles">
        <main className="container">
          <h1>Aktuelles</h1>
          {data.map(({ node }) => (
            <News titel={node.titel} date={node.date} content={node.content} />
          ))}
        </main>
      </Layout>
    </>
  );
};

export const query = graphql`
  query NewsPageQuery {
    allNewsYaml {
      edges {
        node {
          content {
            text
            type
            src {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
                fixed {
                  aspectRatio
                }
              }
            }
          }
          date
          titel
        }
      }
    }
  }
`;

export default NewsPage;
