import { GatsbyImage } from "gatsby-plugin-image";
import * as React from "react";

export const News = ({ titel, date, content }) => (
  <article className="mt-5">
    <b>{titel}</b>
    <p className="float-end">{date}</p>
    {content &&
      content.map((item) => {
        if (item.type === "text") {
          return <p className="pt-2">{item.text}</p>;
        } else {
          return (
            <div className="row justify-content-center">
              {item.src.map((img) => (
                <div
                  className={
                    img.childImageSharp.fixed.aspectRatio < 1
                      ? "col-md-3"
                      : "col-md-6"
                  }
                >
                  <GatsbyImage
                    className="pt-2 shadow"
                    image={img.childImageSharp.gatsbyImageData}
                    alt={"img"}
                    loading="lazy"
                  />
                </div>
              ))}
            </div>
          );
        }
      })}
  </article>
);
